<template>
  <div class="app">
    <navbar title="我的反馈"></navbar>
    <div class="box" v-if="list.length > 0">
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
        @click="tab(item)"
      >
        <div class="item">
          <div class="title">
            {{ item.question }}
            <div>{{ item.status == 1 ? "已回答" : "未回答" }}</div>
          </div>
          <div class="time">
            <img src="../assets/time.png" />
            <div class="tiems">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tac">期待您的第一次吐槽</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    ...mapActions(["getsuggest"]),
    tab(item) {
      this.$router.push({
        path: "/suggest",
        query: { is_type: 1, ...item },
      });
    },
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
    }),
  },
  mounted() {
    this.getsuggest({
      member_id: this.userinfo.id,
      page: "1",
      pageSize: "100",
    }).then((res) => {
      if (res.SuccessCode == 200) {
        this.list = res.ResponseBody.data;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding-top: 0.25rem;
}

.list {
  padding: 0.1rem;
}

.item {
  background-color: #fff;
  padding: 0.1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.time {
  display: flex;
  margin-top: 0.25rem;
}

.time img {
  margin-top: 0.08rem;
  vertical-align: top;
  width: 0.26rem;
  height: 0.26rem;
}

.tiems {
  margin-left: 0.15rem;
  color: #cfcfcf;
  font-size: 0.36rem;
}

.comment {
  margin-left: 3rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cfcfcf;
}

.noFeedBack {
  text-align: center;
  margin-top: 2rem;
}
</style>